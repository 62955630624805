import App from '@/App.vue'
import Vue from 'vue'
import router from '@/router'
import { Modeable, Themeable } from '@mixins'
import { store } from '@/store'
import { i18n } from '@/i18n'

const {
  NODE_ENV,
  VUE_APP_GUIDE_PATH,
  VUE_APP_AUTH_PATH
} = process.env

// глобальные опции vue
const data = {
  isDev: NODE_ENV === 'development',
  VUE_APP_GUIDE_PATH,
  VUE_APP_AUTH_PATH,
  error: {
    message: i18n.t('site.message.goToTestLink')
  }
}

Vue.config.productionTip = false

const instance = new Vue({
  i18n,
  data,
  store,
  router,
  mixins: [
    Themeable,
    Modeable
  ],
  render: h => h(App)
}).$mount('#app')

export default instance
