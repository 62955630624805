<template>
  <div
      ref="container"
      :class="classesMain"
      class="container l-content-widget"
  >
    <section class="l-content-widget__container">
      <aside
          :style="styleWidget"
          class="l-content-widget__widget"
      >
        <slot name="widget"/>
      </aside>

      <article class="l-content-widget__content">
        <slot/>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LContentWidget',

  props: {
    fullHeight: Boolean,
    sticky: Boolean,
    noIdent: Boolean
  },

  data () {
    return {
      widget: {
        maxHeight: 'auto'
      }
    }
  },

  computed: {
    classesMain () {
      return {
        'l-content-widget_type_sticky': this.sticky,
        'l-content-widget_type_full-height': this.fullHeight,
        'l-content-widget_type_no-indent': this.noIdent
      }
    },
    styleWidget () {
      return {
        maxHeight: this.widget.maxHeight
      }
    }
  },

  mounted () {
    if (this.fullHeight) this.setWidgetHeight()
  },

  methods: {
    setWidgetHeight () {
      this.$nextTick(() => {
        const { container } = this.$refs
        const scrollHeight = Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        )
        const footer = scrollHeight - container.clientHeight - container.offsetTop

        this.widget.maxHeight = `${window.innerHeight - container.offsetTop - footer - 82}px`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.l-content-widget {
  min-height: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 1rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }

  &__content,
  &__widget {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  &__content {
    @include media-breakpoint-up(xl) {
      order: 1;
      flex: 0 0 75%;
      max-width: 75%;
    }
  }

  &__widget {
    margin-bottom: 1rem;

    @include media-breakpoint-up(xl) {
      order: 2;
      flex: 0 0 25%;
      max-width: 25%;
      margin-bottom: 0;
    }
  }

  &_type {
    &_sticky {
      .l-content-widget__widget {
        z-index: 3;
        position: sticky;
        top: 1rem;
        bottom: 1rem;
      }
    }

    &_no-indent {
      padding: 0 !important;
    }
  }
}
</style>
